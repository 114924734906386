<template>
  <AppMainContainer center-class="offer">
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot && !officeExists" />
      <OfficeSelect />
    </template>
    <template #center>
      <div
        v-if="officeExists"
        class="d-flex"
      >
        <h1 class="mr-auto">
          {{ $t('header.navigational.info.stats') }}
        </h1>
        <AppDateFilter
          :disabled="loader"
          @changeFilters="changeFilters"
        />
      </div>
      <div
        v-if="!loader"
        class="row"
      >
        <template v-if="stats">
          <div
            v-for="(card, cardKey) in cards"
            :key="cardKey"
            class="col-6"
          >
            <div class="stats-card">
              <h2>{{ card.title }}</h2>
              <template v-if="stats[cardKey]">
                <div
                  v-for="it in card.items"
                  :key="it.key"
                  class="stats-card__item"
                  :class="{'border-bottom pb-2 mb-3': it.borderBottom}"
                >
                  <span class="stats-card__key">
                    {{ it.label }}
                  </span>
                  <AppTextMoney
                    v-if="it.isMoney"
                    class="stats-card__value"
                    :money="stats[cardKey][it.key]"
                  />
                  <span
                    v-else
                    class="stats-card__value"
                    :class="{'text-green cursor-pointer': it.isLink}"
                    @click="goToIncidents(it.isLink)"
                  >
                    {{ stats[cardKey][it.key] }}
                    <span v-if="it.isTime"> ч.</span>
                    <span v-if="it.isPercent">%</span>
                  </span>
                </div>
              </template>
              <span
                v-else
                class="stats-card__no-data"
              >
                Нет данных на указанные даты
              </span>
            </div>
          </div>
        </template>
      </div>
      <AppLoader
        v-else
        class="full-height"
      />
    </template>
  </AppMainContainer>
</template>

<script>
import userUtil from '@/utils/user-util'
import OfficeSelect from '@/components/common/OfficeSelect'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import AppDateFilter from '@/components/common/AppDateFilter'
import AppTextMoney from '@/components/common/AppTextMoney'
import AppLoader from '@/components/AppLoader'

import { mapActions } from 'vuex'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  name: 'PageCompanyProfile',
  components: {
    PageHeader,
    AppMainContainer,
    AppDateFilter,
    AppTextMoney,
    AppLoader,
    OfficeSelect,
  },
  data: () => ({
    stats: null,
    loader: false,
    filters: {},
    cards: {
      offerStats: {
        title: 'Офферы',
        items: [{
          label: 'Количество купленных офферов',
          key: 'purchasedOffersCount',
        }, {
          label: 'Количество ДС потраченных на офферы',
          key: 'purchasedOffersCost',
          isMoney: true,
        }, {
          label: 'Средняя стоимость оффера',
          key: 'averageOfferCost',
          borderBottom: true,
          isMoney: true,
        }, {
          label: 'Количество размещенных офферов',
          key: 'placedOffersCount',
        }, {
          label: 'Количество неуспешных офферов',
          key: 'failedOffersCount',
        }]
      },
      shiftStats: {
        title: 'Смены',
        items: [{
          label: 'Количество успешных смен',
          key: 'successfulRelays',
        }, {
          label: 'Количество досрочно завершенных смен',
          key: 'earlyEndedRelays',
        }, {
          label: 'Средняя продолжительность смены',
          key: 'averageRelayHours',
          isTime: true,
        }, {
          label: 'Средний ФОТ за смену',
          key: 'averageRelayCost',
          isMoney: true,
        }, {
          label: 'Количество ДС потраченных на ФОТ',
          key: 'totalRelayCost',
          isMoney: true,
        }]
      },
      reputationStats: {
        title: 'Репутации',
        items: [{
          label: 'Средняя оценка репутации соискателей',
          key: 'averageLaborerReputation',
        }, {
          label: 'Процент неудачных приглашений',
          key: 'canceledOfferInvitationsCount',
          isPercent: true,
        }, {
          label: 'Средняя оценка рейтинга объекта',
          key: 'averageOfficeRating',
        }, {
          label: 'Количество инцидентов на объекте',
          key: 'officeIncidentsCount',
          isLink: true,
        }]
      },
      financialStats: {
        title: 'Финансы',
        items: [{
          label: 'Приходы за период',
          key: 'totalRefill',
          isMoney: true,
        }, {
          label: 'Расходы за период',
          key: 'totalExpense',
          isMoney: true,
        }]
      }
    }
  }),
  computed: {
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    user () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.user) || userUtil.isAdmin(this.user)
    },
  },
  watch: {
    officeExists (val, prev) {
      if (val && !prev) {
        this.getStats()
      }
    },
    '$route.params.id': function (officeId) {
      if (!officeId) {
        this.stats = null
        return
      }
      this.getStats()
    }
  },
  async created () {
    if (this.officeExists || !userUtil.isRoot(this.user)) {
      await this.getStats()
    }
  },
  methods: {
    ...mapActions({
      fetchOfferStats: 'stats/fetchOfferStats',
      fetchShiftStats: 'stats/fetchShiftStats',
      fetchFinancialStats: 'stats/fetchFinancialStats',
      fetchReputationStats: 'stats/fetchReputationStats',
    }),
    async getStats () {
      this.loader = true
      try {
        const [
          { data: [offerStats = null] },
          { data: [shiftStats = null] },
          { data: [financialStats = null] },
          { data: [reputationStats = null] }
        ] = await Promise.all([
          this.fetchOfferStats(this.filters),
          this.fetchShiftStats(this.filters),
          this.fetchFinancialStats(this.filters),
          this.fetchReputationStats(this.filters)
        ])

        this.stats = {
          offerStats,
          shiftStats,
          financialStats,
          reputationStats
        }
      } finally {
        this.loader = false
      }
    },
    changeFilters (params) {
      this.filters = { ...params }
      this.getStats()
    },
    goToIncidents (isIncidentsLink) {
      if (!isIncidentsLink) {
        return
      }

      this.$router.push(`/office/${this.$route.params.id}/incidents`)
    }
  }
}
</script>

<style lang="sass" scoped>
.stats-card
  position: relative
  min-height: 272px
  padding: 25px
  margin-bottom: 30px
  border: 1.5px solid rgba(0, 0, 0, 0.1)
  background: white
  box-sizing: border-box
  box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
  border-radius: 6px

  h2
    margin-bottom: 19px
    font-size: 20px
    line-height: 24px
    opacity: 0.9

  &__item
    display: flex
    justify-content: space-between
    align-items: baseline
    margin-bottom: 10px
    font-size: 12px
    line-height: 15px
    color: #000000

    &:last-child
      margin-bottom: 0

  &__key
      opacity: 0.4

  &__value
    font-size: 16px
    line-height: 20px
    opacity: 0.9
    font-weight: 800

  &__no-data
    position: absolute
    text-align: center
    width: 150px
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
</style>
